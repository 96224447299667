.w95 {
    width: 95%;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .main-blog{
    background-color: rgb(204, 179, 179);
  }
  

.button-active{
    background-color: #0391C1 !important;

}

.h30{
    height: 160px;
}

.bs{
    box-shadow: 1px 5px 5px 4px rgba(166, 158, 158, 0.6);
    -webkit-box-shadow: 1px 5px 5px 4px rgba(166, 158, 158, 0.6);
    -moz-box-shadow: 1px 5px 5px 4px rgba(166, 158, 158, 0.6);
    border-radius: 2px;
}

.self-center{
  color:white !important;
}