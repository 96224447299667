.ci{
    height: fit-content;
    background-image: url(../static/contact.png);
    background-size: cover;
    padding-top: 20px;
    padding-bottom: 20px;
  }
 
  .borderr{
    border: 1px solid  rgb(171, 171, 222);
  }
 
 .newsletter{
    background: linear-gradient(225.44deg, #0391C1 1.05%, #0391C1 39.55%, #3636B2 100.89%);
    
 }

 input{

  outline: none;

 }

 .newsletter01{
   background: linear-gradient(225.44deg, #0391C1 1.05%, #0391C1 39.55%, #3636B2 100.89%);
 }

 .crop{
  border-bottom-left-radius: 25%;
  border-bottom-right-radius: 25%;
 }

 .semicircle{
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
 }
 
 footer{
    background-color: #161C2D;
 }

 .box-shadow {
  -webkit-box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.08);
}


 .active{
   color: #0391C1 !important;
 }

@media (max-width:640px) {
  .xs{
    width:100%;
  }
}

@media (max-width:768px) {
 .whitebg{
  background-color: white;
 }
}