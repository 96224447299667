.portfolio-container{
    background: linear-gradient(225.44deg, #0391C1 1.05%, #0391C1 39.55%, #3636B2 100.89%);

}
.w95{
    width: 90%;
    height: fit-content;
}
.project-button{
    border: 1px solid black;
    border-radius: 8px;
}
.w49{
    width: 49% !important;
}
.button-active{
    background-color: #0391C1 !important;
}

.font500{
    font-weight: 550;
}
 
.bs{
    box-shadow: 1px 5px 5px 4px rgba(166, 158, 158, 0.6);
    -webkit-box-shadow: 1px 5px 5px 4px rgba(166, 158, 158, 0.6);
    -moz-box-shadow: 1px 5px 5px 4px rgba(166, 158, 158, 0.6);
    border-radius: 8px;
}