@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');
main{
    width: 100% !important;
    height: calc(100vh - 80px );

 }

 .font{
  font-family:'Pacifico', 'Segoe UI', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 }



 .rsc-header{
  background-color: rgb(59 130 246) !important;
 }
 .lgshow-none{
  display: none;
 }

 .about{
    color: white;
    background-image:linear-gradient(rgba(29, 25, 25, 0.6),rgba(19, 18, 18, 0.6)) ,url(../static/about01.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
 }

 .process{
   border:1px solid #161C2D ; width : 264px ; height:60px; border-radius:60px;
 }

 .smfull{
  width: 438px;
 }

 .box{
    box-shadow: 1px 5px 5px 4px rgba(166, 158, 158, 0.6);
    -webkit-box-shadow: 1px 5px 5px 4px rgba(166, 158, 158, 0.6);
    -moz-box-shadow: 1px 5px 5px 4px rgba(166, 158, 158, 0.6);
    border-radius: 10px;
    width: 80%;
    height: 500px;
 }

 .choose{
  height: 500px;
}

 .h600{
   height: 120px;
 }

 .w49{
   width:49%;
 }

 .w91{
   width: 91%;
 }

 /* .polygon{
   width: 120px;
   height: 120px;
 }

  .octagon {
   clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
        background-color: blue;
    }

    .dot {
        width: 20px;
        height: 20px;
        background-color: blue;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    } */

 .bs{
   background-size: cover;
   width: 42%;
   height: 350px;
   background-position: center;
     box-shadow: 1px 5px 5px 4px rgba(166, 158, 158, 0.6);
    -webkit-box-shadow: 1px 5px 5px 4px rgba(166, 158, 158, 0.6);
    -moz-box-shadow: 1px 5px 5px 4px rgba(166, 158, 158, 0.6);
    border-radius: 10px;
 }

 @media (max-width:950px) {
    .bs{
      width: 45%;
    }
 }

 @media (max-width:767px) {
    .odd,.even{
      position:static;
      transform: none;
      top: auto;
    }

    main{
      width: 100% !important;
      height: 750px ;
  
   }

    .m10{
      margin-top: 10px;
     }

    .pl-cancel{
      padding-left: 0 !important;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }

    .al-cancel{
      align-items: initial;
    }

    .pr-cancel{
      padding-right: 0 !important;
    }

    .bs{
      box-shadow: none;
     }

     .d-none{
  display: none;
 }

 .lgshow-none{
  display: block;
 }

 .header{
  display: block;
  padding-top: 120px;

 }

 .header div h1{
  text-align: center;
 }

  .box{
    box-shadow: none;
    width: calc(100% - 20px);
  }

  .w91{
    width: 99%;
    justify-content: center;
  }

  .w912{
    width: 95%;
    justify-content: center;
  }

  .bs{
    width: 99%;
    -webkit-box-shadow:none;
    box-shadow: none;
  }

    .w-cancel{
      width:100%;
      font-family: 'Open Sans', sans-serif;
      font-size: 16px;
      font-weight: 500;
    }



    .tr-cancel{
      text-align: initial;
    }

    .even-holder{
      flex-direction: column;
    }
    .holder{
      flex-direction: column-reverse;
    }

    .j-e{
      justify-content: center;
    }

    .smfull{
      width: 90%;
    }

 }

 @media (max-width: 640px) {
    .w912{
      width: 80%;
    }

    .mb30{
      margin-bottom: 30px;
    }
}

 .active{
   color: #0391C1 !important;
 }

 .bx{
   box-shadow: 1px 5px 5px 4px rgba(166, 158, 158, 0.6);
   -webkit-box-shadow: 1px 5px 5px 4px rgba(166, 158, 158, 0.6);
   -moz-box-shadow: 1px 5px 5px 4px rgba(166, 158, 158, 0.6);
   border-radius: 10px;
 }

 .discovery{
    background-image: url(../static/discovery.png);
 }
 .prototype{
    background-image: url(../static/prototype.png);
 }


 .three{
  background-image: url(../static/image03.png);
 }

 .four{
  background-image: url(../static/image04.png);
 }

 .five{
  background-image: url(../static/image05.png); ;
 }
 .template{
   display: grid;
   grid-template-columns: auto auto;
 }

 .hr{
   color: black;
   font-size: 10px;
 }

 .wfit{
  width: fit-content !important;
 }

 .text-gradient {
  background: linear-gradient(90deg, #ffbd12 0, #6b3de0 53.72%, #3821e9 101.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-main{
  border-radius: 24px;
  background: rgba(255, 255, 255, .08);
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
}

.text-cool-icon-color {
  color: #4A90E2; /* Replace with your preferred color */
}

.animate-fade-in {
  animation: fade-in 0.5s ease-out;
}

.animate-click {
  animation: click 0.3s ease-out;
}

.transition-opacity {
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.background-image {
  position: relative;
  background-size: cover;
  background-image: url('../static/header.jfif'), linear-gradient(rgba(6, 3, 3, 0.1), rgba(0, 0, 0, 0.5));
}

.background-image::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
}

